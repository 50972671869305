<template>
  <div class="dialog-loading modal">
    <div class="modal__body dialog-loading__body">
      Тут загрузка
    </div>
    <div class="modal__overlay" />
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss">
.dialog-loading {
  z-index: 999;
}
</style>
